import React from 'react'
import {mdx} from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
/* @jsxFrag mdx.Fragment */
const MDXLayout = "wrapper";
function MDXContent({components, ...props}) {
  return <MDXLayout components={components} {...props}><h1>{"Installation"}</h1><h2>{"With Aftman (preferred)"}</h2><p><a href="https://github.com/LPGhatguy/aftman" parentName="p">{"Aftman"}</a>{" is the toolchain manager we recommend. You can use it to install Wally:"}</p><p>{"In your project (preferred)"}</p><pre><code className="language-bash" parentName="pre">{"aftman init\naftman add UpliftGames/wally\naftman install\n"}</code></pre><p>{"Or install wally globally"}</p><pre><code className="language-bash" parentName="pre">{"aftman add --global UpliftGames/wally\naftman install\n"}</code></pre><h2>{"Homebrew"}</h2><p><a href="https://brew.sh/" parentName="p">{"Homebrew"}</a>{" is also a convenient way to install wally! (only for MacOS/Linux)"}</p><pre><code className="language-bash" parentName="pre">{"brew install wally\n"}</code></pre><h2>{"From GitHub"}</h2><p>{"Pre-built binaries are available for Windows, macOS, and Linux from the "}<a href="https://github.com/UpliftGames/wally/releases" parentName="p">{"GitHub Releases Page for Wally"}</a>{"."}</p><h2>{"From Source"}</h2><p>{"It's straightforward to compile Wally from source. Wally requires Rust 1.51.0 or newer."}</p><p>{"Clone the repository and use:"}</p><pre><code className="language-bash" parentName="pre">{"cargo install --locked --path .\n"}</code></pre></MDXLayout>;
}
MDXContent.isMDXComponent = true;
export default MDXContent;
