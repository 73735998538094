const mockPopularPackages = [
  {
    package: {
      name: "evaera/cmdr",
      version: "1.8.5",
      registry: "https://github.com/upliftgames/wally-test-index",
      realm: "shared",
      description: "Extensible command console for Roblox developers",
      license: "MIT",
      authors: ["evaera <email@domain.com>"],
    },
    dependencies: {},
    "dev-dependencies": {},
    "server-dependencies": {},
  },
  {
    package: {
      authors: [],
      description: "A view management library for Roblox Lua similar to React",
      license: "Apache-2.0",
      name: "roblox/roact",
      realm: "shared",
      registry: "https://github.com/UpliftGames/wally-index",
      version: "1.4.2",
    },
    dependencies: {},
    "dev-dependencies": {},
    "server-dependencies": {},
  },
  {
    package: {
      name: "evaera/promise",
      version: "3.1.0",
      registry: "https://github.com/UpliftGames/wally-index",
      realm: "shared",
      description: "Promise implementation for Roblox",
      license: "MIT",
      authors: [],
    },
    dependencies: {},
    "dev-dependencies": {},
    "server-dependencies": {},
  },
  {
    dependencies: {},
    "dev-dependencies": {},
    package: {
      authors: [],
      description: "BDD-style test and assertion library for Roblox Lua",
      license: "Apache2",
      name: "roblox/testez",
      realm: "shared",
      registry: "https://github.com/UpliftGames/wally-index",
      version: "0.4.1",
    },
    "server-dependencies": {},
  },
  {
    dependencies: {
      Comm: "sleitnick/comm@>=0.1.0, <0.2.0",
      Loader: "sleitnick/loader@>=1.0.0, <2.0.0",
      Promise: "evaera/promise@>=3.1.0, <4.0.0",
      Signal: "sleitnick/signal@>=1.0.0, <2.0.0",
      TableUtil: "sleitnick/table-util@>=1.0.0, <2.0.0",
    },
    "dev-dependencies": {},
    package: {
      authors: [],
      description: "Knit is a lightweight game framework",
      license: "MIT",
      name: "sleitnick/knit",
      realm: "shared",
      registry: "https://github.com/UpliftGames/wally-index",
      version: "1.0.9",
    },
    "server-dependencies": {},
  },
  {
    dependencies: {},
    "dev-dependencies": {},
    package: {
      authors: [],
      description: "Garbage collector object implementation for Roblox",
      license: null,
      name: "howmanysmall/janitor",
      realm: "shared",
      registry: "https://github.com/UpliftGames/wally-index",
      version: "1.13.13",
    },
    "server-dependencies": {},
  },
]

export default mockPopularPackages
